<template>
  <v-row
    v-if="anamnesis"
    style="flex: 0.5 1 auto;"
  >
    <div class="company-logo-wrapper col-12 pa-0 d-flex justify-center">
      <v-img
        alt="Company Logo"
        class="my-2"
        :src="require('@/../public/img/logo-markero.png')"
        max-width="160"
        contain
      />
    </div>
    <v-col
      cols="11"
      lg="6"
      class="anamnesis mx-auto ma-0 pa-0 d-flex flex-column justify-center"
    >
      <v-fade-transition>
        <Website
          v-if="activeStep === 'website'"
          :website-url="anamnesis.websiteUrl"
          @websiteUrl="anamnesis.websiteUrl = $event"
          @next="createAnamnesisFromWebsite"
        />
        <Process
          v-else-if="activeStep === 'process'"
          :completed="completed"
          @back="updateActiveStep('website')"
          @next="handleProcessed"
        />
        <WebsiteRetry
          v-if="activeStep === 'website-retry'"
          :website-url="anamnesis.websiteUrl"
          @websiteUrl="anamnesis.websiteUrl = $event"
          @next="createAnamnesisFromWebsite"
          @back="createAnamnesisFromWebsite(true)"
        />
        <BasicInfo
          v-else-if="activeStep === 'basic-info'"
          :country="anamnesis.country"
          :completed-fields="completedFields"
          :name="anamnesis.name"
          :website-url="anamnesis.websiteUrl"
          :industry="anamnesis.industry"
          :business-description="anamnesis.businessDescription"
          @country="anamnesis.country = $event"
          @name="anamnesis.name = $event"
          @industry="anamnesis.industry = $event"
          @businessDescription="anamnesis.businessDescription = $event"
          @back="updateActiveStep('website')"
          @next="saveAnamnesis"
        />
        <Success v-else-if="activeStep === 'success'" />
      </v-fade-transition>
    </v-col>
  </v-row>
</template>

<script>
import BasicInfo from '@/components/product-finder/BasicInfo.vue'
import Website from '@/components/product-finder/Website.vue'
import WebsiteRetry from '@/components/product-finder/WebsiteRetry.vue'
import Success from '@/components/product-finder/Success.vue'
import Process from '@/components/product-finder/Process.vue'
import brandingMixin from '@/mixins/branding'

import CREATE_COMPANY_ANAMNESIS from '@/modules/auth/Register/queries/createCompanyAnamnesis.gql'
import CREATE_ANAMNESIS_FROM_WEBSITE from './queries/createAnamnesisFromWebsite.gql'
import COMPANY from './queries/company.gql'
import { formatHttpsUrl } from '@/lib/urlFormatter'
import { showSnackbarMessage } from '@/lib/snackbarMessages'
import countries from '@/mixins/countries'
import { Routes } from '@/components/product-finder/routes'

export default {
  components: { Success, BasicInfo, Website, WebsiteRetry, Process },
  mixins: [brandingMixin, countries],
  data () {
    return {
      activeStep: 'website',
      anamnesis: null,
      completed: false
    }
  },
  computed: {
    isDefaultRoute () {
      return this.$route.params.step === Routes.WEBSITE
    },
    completedFields () {
      return {
        name: !!this.company.name,
        country: !!this.company.country,
        industry: !!this.company.meta?.industry,
        businessDescription: !!this.company.meta?.businessDescription
      }
    }
  },
  watch: {
    $route (to) {
      const stepMapping = {
        [Routes.WEBSITE]: 'website',
        [Routes.INFO]: 'basic-info'
      }

      const activeStep = stepMapping[to.params.step]

      if (activeStep) {
        this.updateActiveStep(activeStep)
      }
    },
    anamnesis: {
      handler (val) {
        sessionStorage.setItem('anamnesis', JSON.stringify(val))
      },
      deep: true
    }
  },
  mounted () {
    if (!this.isDefaultRoute) {
      this.$router.push({ path: `/signup-markero/${Routes.WEBSITE}` })
    }

    this.anamnesis = {
      country: this.availableCountries[0],
      websiteUrl: '',
      name: '',
      industry: '',
      businessDescription: '',
      ...JSON.parse(sessionStorage.getItem('anamnesis'))
    }
  },
  apollo: {
    company: {
      query: COMPANY,
      variables () {
        return { id: this.$auth.user.companyId }
      },
      update ({ company }) {
        const session = JSON.parse(sessionStorage.getItem('anamnesis'))
        this.anamnesis.name = company.name || session.name
        this.anamnesis.websiteUrl = company.links?.websiteUrl || session.websiteUrl
        this.anamnesis.country = this.availableCountries.find(country => country.countryCode === company.country) || session.country
        this.anamnesis.industry = company.meta?.industry ? company.meta?.industry : session.industry
        this.anamnesis.businessDescription = company.meta?.businessDescription || session.businessDescription
        return company
      }
    }
  },
  methods: {
    handleProcessed () {
      if (!this.company.name || !this.company.country || !this.company.meta?.industry || !this.company.meta?.businessDescription) {
        return this.updateActiveStep('basic-info')
      } else {
        this.updateActiveStep('success')
      }
    },
    updateActiveStep (step) {
      // Fade out the current step before fading in the new one
      this.activeStep = ''
      setTimeout(() => {
        this.activeStep = step
      }, 300)
    },
    async createAnamnesisFromWebsite (noWebsite) {
      if (noWebsite) {
        await this.$router.push({ path: `/signup-markero/${Routes.INFO}` })
        this.updateActiveStep('basic-info')
        return
      }
      this.completed = false
      this.updateActiveStep('process')
      try {
        await this.$apollo.mutate({
          mutation: CREATE_ANAMNESIS_FROM_WEBSITE,
          variables: {
            url: formatHttpsUrl(this.anamnesis.websiteUrl)
          },
          refetchQueries: [{ query: COMPANY, variables: { id: this.$auth.user.companyId } }]
        })
      } catch (error) {
        this.updateActiveStep('website-retry')
      }
      this.completed = true
    },
    async saveAnamnesis () {
      try {
        await this.$apollo.mutate({
          mutation: CREATE_COMPANY_ANAMNESIS,
          variables: {
            input: {
              businessDescription: this.anamnesis.businessDescription,
              name: this.anamnesis.name,
              country: this.anamnesis.country.countryCode,
              industry: this.anamnesis.industry,
              websiteUrl: this.anamnesis.websiteUrl.length ? formatHttpsUrl(this.anamnesis.websiteUrl) : ''
            }
          }
        })
      } catch (error) {
        showSnackbarMessage('error', this.$t('product-finder.generate.error'))
        throw error
      }
      this.updateActiveStep('success')
      sessionStorage.removeItem('anamnesis')
    }
  }
}
</script>

<style scoped>
.anamnesis {
  max-width: 500px;
  font-family: 'Poppins', sans-serif;
}
.company-logo-wrapper {
  height: 60px;
}
</style>
