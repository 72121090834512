<template>
  <div class="pa-sm-0 text-center d-flex flex-column gap-1 gap-lg-2 ">
    <div class="mb-5">
      <h1 class="justify-center mx-auto px-sm-0">
        <span
          v-if="isCurrentStepName"
          class="title-text"
        >
          {{ $t('product-finder.basic-info.title-step-one') }}
        </span>
        <span
          v-if="isCurrentStepIndustry"
          class="title-text"
        >
          {{ $t('product-finder.basic-info.title-step-two') }}
        </span>
      </h1>
    </div>

    <div v-if="isCurrentStepName">
      <h4 class="text-start pb-2 link-label">
        {{ $t('product-finder.basic-info.company-name-title') }}
      </h4>
      <v-text-field
        :value="name"
        class="ma-0 pa-0 name-input"
        hide-details
        outlined
        @click="onNameClick()"
        @input="$emit('name', $event)"
      />
    </div>

    <div v-if="isCurrentStepCountry">
      <h4 class="text-start pb-2 link-label">
        {{ $t('product-finder.basic-info.country-box-title') }}
      </h4>
      <v-autocomplete
        :value="country"
        :items="availableCountries"
        clearable
        item-text="text"
        item-value="value"
        outlined
        hide-details
        :placeholder="$t('product-finder.basic-info.country-placeholder')"
        class="pointer"
        return-object
        @input="$emit('country', $event)"
        @click="onCountryClick()"
        @blur="onCountryBlur()"
        @click:clear="$emit('country', null)"
      >
        <template v-slot:selection="data">
          <span>{{ data.item.text }}</span>
        </template>
        <template v-slot:item="data">
          <span class="ml-2">{{ data.item.text }}</span>
        </template>
      </v-autocomplete>
    </div>

    <div v-if="isCurrentStepIndustry">
      <h4 class="text-start pb-2 link-label">
        {{ $t('product-finder.additional-info.industry-box-title') }}
      </h4>
      <v-text-field
        :value="industry"
        hide-details
        outlined
        @click="onIndustryClick()"
        @input="$emit('industry', $event)"
      />
    </div>

    <div v-if="isCurrentStepDescription">
      <h4 class="pb-2 text-start link-label">
        {{ $t('product-finder.additional-info.description-box-title') }}
      </h4>
      <v-textarea
        ref="businessDescription"
        :value="businessDescription"
        :placeholder="$t('product-finder.enter-description-placeholder')"
        rows="5"
        class="mt-2"
        outlined
        counter="5000"
        :rules="[rules.lengthWithEmptySpace({max: 5000})]"
        @input="$emit('businessDescription', $event)"
        @click="onBusinessDescriptionClick()"
      />
    </div>

    <div class="d-flex gap-1 mt-10">
      <Button @click="changeRouteBack()">
        <span class="proceed">{{ $t('buttons.product-finder.back') }}</span>
      </Button>
      <Button
        primary
        @click="changeRouteNext()"
      >
        <span
          v-if="isCurrentStepDescription"
          class="proceed"
        >
          {{ $t('buttons.product-finder.finish') }}
        </span>
        <span
          v-else
          class="proceed"
        >{{ $t('buttons.product-finder.next') }}</span>
      </Button>
    </div>
  </div>
</template>

<script>
import countries from '@/mixins/countries'
import Button from './Button.vue'
import { lengthWithEmptySpace } from '@/lib/validation'
import { Routes } from '@/components/product-finder/routes'
export default {
  components: { Button },
  mixins: [countries],
  props: {
    name: {
      type: String,
      default: ''
    },
    country: {
      type: Object,
      default: () => ({})
    },
    completedFields: {
      type: Object,
      default: () => ({})
    },
    industry: {
      type: String,
      default: ''
    },
    businessDescription: {
      type: String,
      default: ''
    }
  },
  computed: {
    Routes () {
      return Routes
    },
    currentStep () {
      return this.$route.params.step
    },
    isActiveField () {
      return (step, field) => {
        return this.currentStep === step && !this.completedFields[field]
      }
    },
    isCurrentStepName () {
      return this.isActiveField(Routes.INFO, 'name')
    },
    isCurrentStepCountry () {
      return this.isActiveField(Routes.ORIGIN, 'country')
    },
    isCurrentStepIndustry () {
      return this.isActiveField(Routes.INDUSTRY, 'industry')
    },
    isCurrentStepDescription () {
      return this.isActiveField(Routes.DESCRIPTION, 'businessDescription')
    },
    stepsList () {
      return [
        { step: Routes.INFO, field: 'name' },
        { step: Routes.ORIGIN, field: 'country' },
        { step: Routes.INDUSTRY, field: 'industry' },
        { step: Routes.DESCRIPTION, field: 'businessDescription' }
      ].filter(item => !this.completedFields[item.field]).map(item => item.step)
    }
  },
  watch: {
    country (val) {
      this.$tracking.event('Account Creation', this.$tracking.trackingEvents.CLICKED, 'Country selected', val.value)
    }
  },
  created () {
    this.rules = { lengthWithEmptySpace }
  },
  methods: {
    changeRouteBack () {
      const previousStep = this.getAdjacentStep('back')
      if (!previousStep) {
        this.$router.push({ path: '/signup-markero/company-info-url' })
        this.$emit('back')
        return
      }

      this.$router.push({ path: `/signup-markero/${previousStep}` })
    },

    changeRouteNext () {
      const nextStep = this.getAdjacentStep('next')
      if (!nextStep) {
        this.$emit('next')
        return
      }

      this.$router.push({ path: `/signup-markero/${nextStep}` })
    },

    getAdjacentStep (direction) {
      const currentIndex = this.stepsList.indexOf(this.$route.params.step)
      if (currentIndex === -1) return this.stepsList[0] || null

      const nextIndex = direction === 'next' ? currentIndex + 1 : currentIndex - 1
      return this.stepsList[nextIndex] || null
    },
    onCountryClick () {
      this.$tracking.event('Account Creation', this.$tracking.trackingEvents.CLICKED, 'Country Selection opened')
    },
    onNameClick () {
      this.$tracking.event('Account Creation', this.$tracking.trackingEvents.CLICKED, 'Name input clicked')
    },
    onIndustryClick () {
      this.$tracking.event('Account Creation', this.$tracking.trackingEvents.CLICKED, 'Industry input clicked')
    },
    onBusinessDescriptionClick () {
      this.$tracking.event('Account Creation', this.$tracking.trackingEvents.CLICKED, 'Business Description clicked')
    },
    onCountryBlur () {
      this.$tracking.event('Account Creation', this.$tracking.trackingEvents.CLICKED, 'Country Selection closed')
    }
  }
}

</script>

<style scoped>
@media (max-width: 600px) {
::v-deep  .v-text-field.v-text-field--enclosed .v-text-field__details {
    margin-bottom: 0;
  }
}

.title-text {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
}
.link-label, .proceed {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}
</style>
