<template>
  <div
    class="pa-sm-0 text-center"
    :style="cssProps"
  >
    <h1 class="title-text justify-center mx-auto px-sm-0">
      {{ $t('product-finder.website.title') }}
    </h1>
    <div class="sub-title-text mt-4">
      {{ $t('product-finder.website.sub-title') }}
    </div>

    <div class="mt-10 mb-2">
      <h4 class="pb-2 text-left link-label">
        {{ $t('product-finder.website.link-label') }}
      </h4>
      <v-form ref="website">
        <v-text-field
          :value="websiteUrl"
          class="website-input"
          outlined
          type="url"
          :placeholder="$t('product-finder.enter-website-placeholder')"
          :rules="noWebsite ? [] : [rules.url, rules.required]"
          :disabled="noWebsite"
          validate-on-blur
          @keydown.enter.prevent="proceed"
          @click="onWebsiteUrlClicked"
          @input="$emit('websiteUrl', $event)"
        />
      </v-form>
      <v-checkbox
        v-model="noWebsite"
        class="my-0 "
        hide-details
      >
        <template v-slot:label>
          <span class="checkbox-text">{{ $t('product-finder.website.no-website') }}</span>
        </template>
      </v-checkbox>
    </div>

    <v-alert
      v-if="noWebsite"
      type="warning"
      class="warning mt-4"
      icon="mdi mdi-alert-circle-outline"
    >
      {{ $t('product-finder.website.warning') }}
    </v-alert>

    <Button
      primary
      class="proceed w-full mt-10"
      @click="proceed"
    >
      {{ $t('buttons.product-finder.next') }}
    </Button>
  </div>
</template>

<script>
import countries from '@/mixins/countries'
import { url, required } from '@/lib/validation'
import Button from './Button.vue'

export default {
  components: { Button },
  mixins: [countries],
  props: {
    websiteUrl: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      noWebsite: false
    }
  },
  computed: {
    cssProps () {
      return {
        '--primary': this.$vuetify.theme.themes.light.primary
      }
    }
  },
  watch: {
    noWebsite (val) {
      const label = val ? 'Website Needed' : 'Website Existing'
      this.$tracking.event('Account Creation', this.$tracking.trackingEvents.CLICKED, label)
    }
  },
  created () {
    this.rules = { url, required }
  },
  methods: {
    proceed () {
      const valid = this.$refs.website.validate()
      if (valid) {
        this.$emit('next', this.noWebsite)
      }
    },
    onWebsiteUrlClicked () {
      this.$tracking.event('Account Creation', this.$tracking.trackingEvents.CLICKED, 'Website URL input')
    }
  }
}
</script>

<style scoped>
::v-deep .v-text-field.v-text-field--enclosed .v-text-field__details {
  margin-bottom: 0;
}
::v-deep .v-alert__icon {
  margin-right: 4px !important;
}

::v-deep .v-alert__content {
  text-align: left !important;
}

::v-deep .v-text-field input {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.warning {
  color: white;
  background-color: var(--primary) !important;
  border-color: var(--primary) !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}
.title-text {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
}
.sub-title-text, .link-label, .checkbox-text, .proceed {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.checkbox-text {
  color: #919090;
}
.link-label, .proceed {
  font-weight: 700;
}
::v-deep .website-input input::placeholder {
  color: #5b5b5b;
  opacity: 1;
}
</style>
